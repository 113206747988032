import React from 'react';
import { Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import "./assets/css/materialdesignicons.min.css";
import "./assets/scss/style.scss";
import DisclamorPolicy from './pages/disclamorPolicy';
import Home from './pages/home';
import IntellectualProperty from './pages/intellectualPropertyPolicy';
import OrderCancellation from './pages/orderCancellation';
import PrivacyPolicy from './pages/privacyPolicy';
import ReturnExchange from './pages/returnExchangeRefundPolicy';
import TermsAndCondition from './pages/termsAndCondition';


function App() {

  return (
    <>
    <Routes>
      <Route path='/'element={<Home/>}/>
      <Route path='/cancelorder' element={<OrderCancellation/>}/>
      <Route path='/termscondition' element={<TermsAndCondition/>}/>
      <Route path='/disclamorpolicy' element={<DisclamorPolicy/>}/>
      <Route path='/returnexchange' element={<ReturnExchange/>}/>
      <Route path='/privacypolicy' element={<PrivacyPolicy/>}/>
      <Route path='/intellectualProperty' element={<IntellectualProperty/>}/>
    </Routes>
    </>
  );
}

export default App;
