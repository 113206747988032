import React from "react";
import { Link } from "react-router-dom";
import twitter from "../../assets//images/twitter-x.svg";
import logoLight from "../../assets/bworth_footer_logo.778fac666349917858e6.png";

import { AiFillLinkedin, AiOutlineInstagram, FaFacebookF } from "../../assets/icons/vander";

export default function Footer(){
    return(
        <>
        <footer className=" bg footer-bar">
            <div className="footer-py-30">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-3">
                            <div className="text-sm-start">
                                <Link to="#" className="logo-footer">
                                    <img  className="footerLogo"src={logoLight} alt=""/>
                                </Link>
                            </div>
                        </div>
                        <div className="col-sm-5 mt-4 mt-sm-0 pt-sm-0">
                            <div className="text-center">
                                <p className="mb-0"> Powered  <i className="mdi mdi-heart text-danger"></i> by <Link to="https://b-worth.in/" target="_blank" className="text-reset">BWorth © {new Date().getFullYear()}</Link></p>
                            </div>
                        </div>
    
                        <div className="col-sm-3 mt-4  mt-sm-0 pt-8 pt-sm-0">
                            <ul className="list-unstyled social-icon foot-social-icon text-sm-start mb-0">
                                {/* <li className="list-inline-item ms-1"><Link to="https://1.envato.market/fronter-react " target="_blank" className="rounded"><FiShoppingCart className=""/></Link></li> */}
                                {/* <li className="list-inline-item ms-1"><Link to="https://dribbble.com/shreethemes" target="_blank" className="rounded"><FiDribbble/></Link></li> */}
                                {/* <li className="list-inline-item ms-1"><Link to="https://www.behance.net/shreethemes" target="_blank" className="rounded"><AiOutlineBehance/></Link></li> */}
                                <li className="list-inline-item ms-1"><Link to="https://www.linkedin.com/company/bworth-technologies/" target="_blank" className="rounded"><AiFillLinkedin/></Link></li>
                                <li className="list-inline-item ms-1"><Link to="https://www.facebook.com/profile.php?id=61565081468088&mibextid=ZbWKwL" target="_blank" className="rounded"><FaFacebookF/></Link></li>
                                <li className="list-inline-item ms-1"><Link to="https://www.instagram.com/bworth_tech?igsh=NnhtOHlidzZqOGI2/" target="_blank" className="rounded"><AiOutlineInstagram/></Link></li>
                                <li className="list-inline-item ms-1"><Link to="https://x.com/BworthT?t=driP3qyT2F2i4Oz2wFOTuQ&s=09/" target="_blank" className="rounded"> <img className="twitter" src={twitter}/> </Link></li>
                                {/* <li className="list-inline-item ms-1"><Link to="mailto:support@shreethemes.in" className="rounded"><FiMail/></Link></li> */}
                                
                                {/* <li className="list-inline-item ms-1"><Link to="https://forms.gle/QkTueCikDGqJnbky9" target="_blank" className="rounded"><FiFileText/></Link></li> */}
                            </ul>
                        </div>
                        
                     
                    </div>
                </div>
            </div>
        </footer>
        </>
    )
}