import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { TypeAnimation } from "react-type-animation";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import mission from "../../src/assets/blue-removebg-preview.png";
import visions from "../../src/assets/Group 46147.png";
import buyback from "../../src/assets/Group_2133-removebg.png";
import serviceVector1 from "../../src/assets/images/Group 2134.png";
import serviceVector3 from "../../src/assets/images/Group 2135.png";
import serviceVector2 from "../../src/assets/images/Group 2136.png";
import imageSrc from "../../src/assets/images/pexels-willoworld-3768005.jpg";
import backgroundImg from "../assets/images/width_1600.png";
import Footer from "../componants/indexJs/footer";
import Navbar from "../componants/indexJs/navbar";
import ScrollTop from "../componants/scrollTop";
import ContactOne from "./contactUs";

export default function Home() {

  const images = [
    {
      avatar: serviceVector3,
      description: " offline and online thrifting platform",
    },
    { avatar: serviceVector1, description: " Offline Events" },
    {
      avatar: serviceVector2,
      description: "Unique Marketplace",
    },
  ];

  return (
    <>
      <Navbar
        navClass="defaultscroll sticky"
        manuClass="navigation-menu nav-right"
        smallButton={false}
      />

      <section className="section" id="home">
        <img className="homeImg" src={backgroundImg} alt="" />
        <div
  className="container containerText"
  style={{
    position: "absolute",
    top: "50%",
    width: "100%",
    padding: "20px",
    float: "right",
    textAlign: "center",
  }}>
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="title-heading">
                <h4 className="displayText  text-Size text-white mt-4 mb-3">
                  Creating sustainable
                  <TypeAnimation
                    sequence={[
                      "Fashion Ecosystem",
                      1000,
                      " Fashion  Ecosystem",
                      1000,
                      "Fashion Ecosystem",
                      1000,
                      " Fashion  Ecosystem",
                      1000,
                    ]}
                    wrapper="span"
                    speed={10}
                    className=" text-Size text-primary1 ps-2"
                    repeat={Infinity}
                  />
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section1 bg-light" id="about-us">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mt-4 mb-4 text-center">
                <h4>About Us</h4>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-6" style={{ height: "554px" }}>
              <img
                className="about-image"
                src={imageSrc}
                alt="Bworth Technologies"
              />
            </div>
            <div className="col-md-6">
              <p className="about-text">
                Founded in April 2024 in Gurugram, Bworth Technologies Pvt Ltd
                addresses the persistent problem of wardrobe clutter, for which
                no established solution exists. Without proper disposal methods,
                used apparel often ends up in landfills, releasing harmful
                carbon emissions that damage our environment. To mitigate this
                environmental impact, Bworth has developed an ecosystem where
                used apparel is repurposed through reuse, upcycling, and
                recycling methods.
              </p>
              <p className="about-text">
                Our vision extends beyond environmental conservation to also
                address changing fashion trends. Our flagship Buyback program
                plays a crucial role in this by helping to reduce the fashion
                industry's carbon footprint and its environmental impact. This
                program allows consumers to participate in a circular economy,
                promoting the sustainable use of fashion.
              </p>
              <p className="about-text">
                Bworth is more than just a company; it is a movement towards
                conscious consumerism. We strive to give every clothing item a
                second chance, significantly reducing environmental damage. Our
                mission is to integrate sustainability into the fabric of
                fashion, thereby preserving the planet's beauty for future
                generations.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section1 bg-light" id="mission">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mt-4 mb-4 text-center">
                <h4>Mission</h4>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-6" style={{ height: "360px" }}>
              <img
                className="about-image"
                src={mission}
                alt="Bworth Technologies"
              />
            </div>
            <div className="col-md-6">
              <p className="about-text">
                Our mission is to revolutionize the fashion industry through a
                unique buyback program that allows consumers to return their
                clothes after a period of use, ensuring that every item has an
                extended lifecycle. We aim to solve the problem of wardrobe
                clutter by offering a sustainable solution that benefits both
                consumers and sellers.
              </p>
              <p className="about-text">
                By organizing offline events in residential societies and
                partnering with brands, we intend to create a direct and
                engaging platform for our customers. We are committed to
                providing a flexible commission program for sellers and ensuring
                the clearance of dead stock, thus promoting a circular economy.
                Our mission is to foster trust and build long-term relationships
                with our customers by offering innovative and sustainable
                fashion solutions.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section1 bg-light" id="vision">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mt-4 mb-4 text-center">
                <h4>Vision</h4>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-6" style={{ height: "330px" }}>
              <img
                className="about-image"
                src={visions}
                alt="Bworth Technologies"
              />
            </div>
            <div className="col-md-6">
              <p className="about-text">
                BWorth Technologies Private Limited envisions creating a
                sustainable ecosystem within the fashion marketplace, where both
                consumers and sellers benefit in a win-win scenario. Our goal is
                to transform the fast fashion industry by promoting
                sustainability and environmental consciousness.
              </p>
              <p className="about-text">
                By addressing the common issue of overfilled wardrobes with
                clothes that are no longer worn but not entirely discarded, we
                aim to provide a solution that not only helps individuals manage
                their wardrobe efficiently but also contributes to environmental
                sustainability. Our vision is to make fashion consumption more
                responsible and to instill a sense of value in every garment
                purchased.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="section1 bg-light" id="vision">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mt-4 mb-4 text-center">
                <h4>Buyback Program</h4>
              </div>
            </div>
          </div>

          <div className="row ">
            <div className="col-md-6" style={{ height: "750px" }}>
              <img
                className="about-image"
                src={buyback}
                alt="Bworth Technologies"
                style={{ height: "100%" }}
              />
            </div>
            <div className="col-md-6">
              <p className="about-text">
                Our company offers a comprehensive solution to manage your
                unwanted clothing while promoting environmental sustainability.
              </p>
              <p className="about-text">
                Firstly, we buy back anything you've purchased from us. This
                initiative ensures that your clothes have a longer lifecycle and
                reduces the amount of textile waste. By participating in our
                buyback program, you not only recoup some of your investment but
                also contribute to a more sustainable fashion industry.
              </p>
              <p className="about-text">
                Secondly, we purchase your old, unwanted clothes that are just
                taking up space in your wardrobe. Regardless of their condition,
                we see value in these garments. By buying your old clothes, we
                provide you with a convenient way to declutter and responsibly
                dispose of items you no longer need.
              </p>
              <p className="about-text">
                Our efforts significantly prevent these old clothes from ending
                up in landfills, where they would contribute to environmental
                pollution. By redirecting these textiles from landfills, we
                mitigate the harmful effects of textile waste on the
                environment.
              </p>
              <p className="about-text">
                Additionally, we enhance the lifecycle of your clothing through
                processes such as reuse, recycling, and upcycling. By giving
                your old clothes a new life, we reduce the demand for new
                textiles and the resources required to produce them. This
                approach promotes a circular economy in the fashion industry,
                where products are continuously repurposed, minimizing waste and
                conserving resources.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section1 bg-light" id="brand">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mt-4 mb-4 text-center">
                <h4>Brand Association</h4>
              </div>
            </div>
          </div>
          <div className="col-12">
            <p className="about-text">
              We are thrilled to announce our collaboration with "Make in India"
              and various "Made in India" brands, bringing you an exceptional
              range of products that celebrate the spirit of Indian
              craftsmanship and innovation. This partnership underscores our
              commitment to supporting local businesses and promoting indigenous
              talent. By joining hands with these brands, we aim to offer our
              customers high-quality, authentic Indian products that reflect the
              rich cultural heritage and modern ingenuity of India.
            </p>
            <p className="about-text">
              Whether it's traditional handicrafts, contemporary fashion, or
              cutting-edge technology, our curated selection ensures that you
              experience the best of what India has to offer. Each product in
              our collection is a testament to the dedication and skill of
              Indian artisans and entrepreneurs. We believe in the power of
              local production and are proud to provide a platform for these
              talented individuals and companies to showcase their work on a
              larger stage.
            </p>
            <p className="about-text">
              Stay tuned as we unveil an exciting array of products designed and
              manufactured in India, showcasing the perfect blend of tradition
              and modernity. Embrace the essence of India with our upcoming
              collections and be a part of this vibrant journey. Your support
              not only helps preserve India's rich cultural legacy but also
              drives innovation and growth within the local economy. Join us in
              celebrating the unique and diverse spirit of India through our
              exclusive range of "Made in India" products.
            </p>
          </div>
        </div>
      </section>

      <section className="section1 bg-light" id="brand">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mt-4 mb-4 text-center">
                <h4>PRODUCTS & SERVICES</h4>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 d-flex justify-content-around flex-wrap">
              {images.map((image, index) => (
                <div key={index} className="p-2 position-relative">
                  <img
                    src={image.avatar}
                    alt={`Product ${index + 1}`}
                    className="img-fluid product-image"
                    style={{ maxWidth: "200px", height: "auto" }}
                  />
                  <div className="image-overlay">
                    <h6 className="overlay-text">{image.description}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <ContactOne />
      <Footer />
      <ScrollTop />
    </>
  );
}
