import React, { useRef } from "react";
import { Link } from "react-router-dom";
import background from "../assets/images/bg/03.jpg"
import { useState } from 'react';
import { Parallax } from 'react-parallax';
import Navbar from "../componants/indexJs/navbar";
import { ToastContainer, toast } from 'react-toastify';
import {BsTelephone,FiMail,FiMapPin} from "../assets/icons/vander"
import ScrollTop from "../componants/scrollTop";
import emailjs from '@emailjs/browser';
export default function ContactOne(){
    const form = useRef();

    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        subject: '',
        comments: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

    emailjs
      .sendForm('service_7cwkg2q', 'template_ccvc12n', form.current, {
        publicKey: 'lykGQAq8cFyBf0hOV',
      })
      .then(
        () => {
            toast.success('Form submitted successfully!');

          console.log('SUCCESS!');
          setFormData({
            user_name: '',
            user_email: '',
            subject: '',
            comments: ''
        })

        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

    return(
        <>
              <ToastContainer />
         <Navbar navClass="defaultscroll sticky" manuClass="navigation-menu nav-right nav-light" logoLight={true}/>
        <section className="position-relative overflow-hidden" id="contact-us">
            <Parallax
                blur={{ min: 0, max: 0}}
                bgImage={background}
                bgImageAlt="the dog"
                strength={500}
                bgportfolioImageize="100%"
                bgStyle={{with:"auto", height:"100%" }}
                style={{position:"absolute", width:"100%" , height:"100%"}}
            >
            </Parallax>
            <div className="bg-overlay bg-gradient-overlay-2"></div>
            <div className="bg-half-170 d-table w-100">
                <div className="container">
                    <div className="row mt-5 justify-content-center">
                        <div className="col-12">
                            <div className="title-heading text-center">
                                <p className="text-white-50 para-desc mx-auto mb-0">Get in touch !</p>
                                <h5 className="heading fw-semibold mb-0 sub-heading text-white title-dark">Contact us</h5>
                            </div>
                        </div>
                    </div>

                    <div className="position-middle-bottom">
                        <nav aria-label="breadcrumb" className="d-block">
                            <ul className="breadcrumb breadcrumb-muted mb-0 p-0">
                                {/* <li className="breadcrumb-item"><Link to="/">BWorth</Link></li> */}
                                {/* <li className="breadcrumb-item active" aria-current="page">Contact us</li> */}
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <div className="position-relative">
            <div className="shape overflow-hidden text-white">
                <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <section className="section pb-0">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="p-4 rounded shadow">
                        <form   ref={form} onSubmit={handleSubmit}>
            <p className="mb-0" id="error-msg"></p>
            <div id="simple-msg"></div>
            <div className="row">
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Your Name <span className="text-danger">*</span></label>
                        <input name="user_name" id="name" type="text" className="form-control" placeholder="Name" onChange={handleChange} value={formData.user_name} />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="mb-3">
                        <label className="form-label">Your Email <span className="text-danger">*</span></label>
                        <input name="user_email" id="email" type="email" className="form-control" placeholder="Email" onChange={handleChange} value={formData.user_email} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="mb-3">
                        <label className="form-label">Subject</label>
                        <input name="subject" id="subject" className="form-control" placeholder="Subject " onChange={handleChange} value={formData.subject} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="mb-3">
                        <label className="form-label">Comments <span className="text-danger">*</span></label>
                        <textarea name="comments" id="comments" rows="4" className="form-control" placeholder="Message" onChange={handleChange} value={formData.comments}></textarea>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="d-grid">
                        <button type="submit" id="submit" name="send" className="btn btn-primary">Send Message</button>
                    </div>
                </div>
            </div>
        </form>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container mt-100 mt-60">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="card border-0 text-center features feature-primary feature-clean">
                            <div className="icons bg-lg text-center rounded-lg  mx-auto">
                                <BsTelephone className="icon d-block h3 mb-0"/>
                            </div>

                            <div className="content mt-4 pt-2 px-4">
                                <h5 className="mb-3">Phone</h5>
                                <Link to="tel:+9188266680504" className="link"> +918826668050</Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-12 mt-5 mt-sm-0">
                        <div className="card border-0 text-center features feature-primary feature-clean">
                            <div className="icons bg-lg text-center rounded-lg  mx-auto">
                                <FiMail className="icon d-block h3 mb-0"/>
                            </div>

                            <div className="content mt-4 pt-2 px-4">
                                <h5 className="mb-3">Email</h5>
                                <Link to="mailto:info@bworth.co.in" className="link">info@bworth.co.in</Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-12 mt-5 mt-lg-0">
                        <div className="card border-0 text-center features feature-primary feature-clean">
                            <div className="icons bg-lg text-center mx-auto rounded-lg ">
                                <FiMapPin className="icon d-block h3 mb-0"/>
                            </div>

                            <div className="content mt-4 pt-2 px-4">
                                <h5 className="mb-3">Location</h5>
                                <p className="text-muted">Gurugram, Haryana</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid mt-100 mt-60">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="card map border-0">
                            <div className="card-body p-0">
                            <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7005.698502723106!2d77.0669699!3d28.4536046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18ee7aac203d%3A0x4a803e5e1fadda2c!2sMinarch%20Tower!5e0!3m2!1sen!2sin!4v1655816461742!5m2!1sen!2sin"
    width="600"
    height="450"
    style={{ border: "0" }}
    allowFullScreen=""
    loading="lazy"
    referrerPolicy="no-referrer-when-downgrade"
    title="Minarch Tower Location"
></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <ScrollTop/>
        </>
    )
}