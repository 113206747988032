import React from 'react';
import Footer from "../componants/indexJs/footer";
import Navbar from "../componants/indexJs/navbar";

const ReturnExchange = () => {
  return (
    <div>
      <Navbar
        navClass="defaultscroll sticky"
        manuClass="navigation-menu nav-right"
        smallButton={false}
      />
      
      <div className="terms-container">
        <div className="iframe-wrapper">
          <iframe 
            src="https://drive.google.com/file/d/1xKy3Sl8twl_ESDzaFy6MLZPYPAlknI3J/preview" 
            className="responsive-iframe"
            title="Terms and Conditions"
          />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ReturnExchange;
